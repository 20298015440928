<template>
    <div>
        <loader v-if="isConfirmationLoading" />
        <st-modal
            ref='modal'
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            hideable
            size="md"
            :title="$t('APPLICATION.CONFIRM_SIGN_MODAL.TITLE')"
            :hideHeaderClose="isLoading"
            customClass="form-modal"
            :id="modalId"
            @modalHidden="closeModal"
        >
            <template #body>
                <form class="form" novalidate="novalidate" id="st_modal_confirm_sign_attachments">
                    <div class="row pb-6">
                        <div class="col">
                            <div class="form-group">
                                <label>{{ fields.pin.label }}</label>
                                <st-input-text
                                    :ref="fields.pin.name"
                                    :name="fields.pin.name"
                                    v-model="model[fields.pin.name]"
                                    set-validation
                                    :max-length="6"
                                />
                            </div>
                        </div>
                        <div class="col-12">
                            <span v-if="confirmationStatus === 'bulk_failed'" class="text-danger">
                                <i18n
                                    path="APPLICATION.SIGN_CONFIRMATION.BULK_FAILED"
                                    tag="span"
                                >
                                    <span class="font-weight-bold">{{ bulkFailedApplications.join(', ') }}</span>
                                </i18n>
                            </span>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <st-button
                            size="large"
                            variant="link"
                            :callback="doBulkSign"
                        > {{ continueText }}
                        </st-button>
                    </div>
                </form>
            </template>
        </st-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ApplicationModel } from "@/modules/applications/models/application-model";
import { createFormValidation } from '@/shared/utils/create-form-validation';
import { generateRandomId } from '@/core/helpers/globalMethods';

const { fields } =  ApplicationModel;

const formSchema = new FormSchema([
   fields.pin,
]);

export default {
    name: 'ApplicationAttachmentsSignModal',
    props: {
        applicationId: {
            type: [String, Array],
            required: true,
        },
        fileIds: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            rules: formSchema.rules(),
            model: null,
            fields,
            fv: null,
            modalId: `st-modal-confirm-attachments-sign-${generateRandomId()}`,
            polling: null,
            confirmationStatus: '',
            isConfirmationLoading: false,
            bulkFailedApplications: []
        }
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            currentUser: 'auth/currentUser'
        }),
        isLoading() {
            return this.loading['applications/confirm-sign'];
        },
        continueText() {
            return this.$t('GENERAL.BUTTON.CONTINUE');
        }
    },
    methods: {
        ...mapActions({
            doValidateBulkAttachmentsSign: 'applications/form/doValidateBulkAttachmentsSign',
        }),
        show(data) {
            if (data.context.failed_identification_numbers) {
                this.confirmationStatus = 'bulk_failed';
                this.bulkFailedApplications = data.context.failed_identification_numbers;
            }

            this.$refs['modal'].show();
        },
        hide() {
            this.model.pin = '';

            this.$refs['modal'].hide();
            this.$emit('refresh');
        },
        closeModal() {
            this.hide();
        },
        async doBulkSign() {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const payload = {
                    applicationId: this.applicationId,
                    file_ids: this.fileIds.map((el) => el.file_id),
                    pin: this.model.pin,
                }

                this.doValidateBulkAttachmentsSign(payload);
                
                return this.hide();
            }
        },
    },
    async created() {
        this.model = formSchema.initialValues({});
    },
    mounted() {
        this.$root.$on('bv::modal::shown', (modalObj) => {
            if (modalObj.componentId === this.modalId) {
                this.fv = createFormValidation(this.modalId, this.rules);
            }
        });
    }
}
</script>
